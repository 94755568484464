import React, { Component } from 'react';
import { Form, Input, Button, Alert } from 'antd';

class DomainSettings extends Component {
  state = {
    saving: this.props.saving
  }

  componentDidUpdate() {
    if (this.state.saving !== this.props.saving) {
      this.setState({ saving: this.props.saving });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.updateSettings(values);
      }
    });
  }

  componentDidMount() {
    this.props.form.setFieldsValue(this.props.settings)
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="smtp-form">
        <Form.Item label="Domain">
          {getFieldDecorator('domain', {
            rules: [{ required: true, message: 'This is a required field' },],
          })(
            <Input disabled={this.props.settings.status === 1 || this.props.settings.status === '1'} />
          )}
        </Form.Item>
        {(this.props.settings.status === 0 || this.props.settings.status === '0') &&
          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button" loading={this.state.saving}>Save</Button>
          </Form.Item>
        }
        <Alert style={{ marginTop: 30 }}
          message="How To Point your Domain"
          description={
            <div class="whitelabel-instructions">
              <p>1. Copy the following URL: franchiseapp.letconvert.com</p>
              <p>2. Navigate to your domain provider</p>
              <p>3. Visit your domain management page</p>
              <p>4. Create a new CNAME record for your domain <i>(sometimes referred to as an alias)</i></p>
              <p>5. In the name field, set the CNAME to the same value as your subdomain <i>(for example, if you selected 'webinar' as your desired subdomain, your CNAME would also be set to 'webinar')</i></p>
              <p>6. In the field labeled value, paste the URL link copied above</p>
              <p>7. Leave all other options set at their recommended default settings</p>
            </div>
          }
          type="info"
        />
      </Form>
    );
  }
}

export default Form.create()(DomainSettings);