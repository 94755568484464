import React, { Component } from 'react';
import { Form, Input, Button, Tooltip, Icon, Checkbox, Row, message } from 'antd';
import Uploader from './Uploader';
const CDN_URL = "http://d3p18rywckubta.cloudfront.net";

class GeneralSettings extends Component {
  state = {
    saving: this.props.saving
  }

  componentDidUpdate() {
    if (this.state.saving !== this.props.saving) {
      this.setState({ saving: this.props.saving });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.updateSettings(values);
      }
    });
  }

  componentDidMount() {
    this.props.form.setFieldsValue(this.props.settings)
  }

  normFile = (e) => {
    if (e.file && e.file.status === "done" && e.file.response) {
      if (e.file.response.success === 1) {
        message.success('Image Uploaded Successfully!');
      } else {
        message.error(e.file.response.msg);
      }
    }
    return e && e.file;
  }

  render() {
    /*
      "Logos -
      1) Small Logo
      2) Full Logo
      3) Favicon
      "Support Settings
      4) Submit Ticket Widget Code
      5) Live Chat Widget Code "
    */
    const { getFieldDecorator, getFieldValue, setFieldsValue } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="smtp-form">
        <Form.Item label={<span>Account Name <Tooltip title="This is for your internal use only"><Icon type="info-circle" theme="filled" style={{ cursor: "pointer" }} /></Tooltip></span>}>
          {getFieldDecorator('account_name', {
            rules: [{ required: true, message: 'This is a required field' }],
          })(
            <Input />
          )}
        </Form.Item>
        <Form.Item label={<span>Franchise Name <Tooltip title="The franchise name will be used for the title of the application and as the basic branding on alerts that your customers will set."><Icon type="info-circle" theme="filled" style={{ cursor: "pointer" }} /></Tooltip></span>}>
          {getFieldDecorator('franchise_name', {
            rules: [{ required: true, message: 'This is a required field' }],
          })(
            <Input />
          )}
        </Form.Item>
        <Form.Item label={<span>Default Branding URL <Tooltip title="This will be set as the linked URL on your base branding."><Icon type="info-circle" theme="filled" style={{ cursor: "pointer" }} /></Tooltip></span>}>
          {getFieldDecorator('branding_url', {
            rules: [{ required: true, message: 'This is a required field' }, { type: 'url', message: 'Please enter a valid URL' }],
          })(
            <Input />
          )}
        </Form.Item>

        <Row gutter={15}>
          <Uploader
            label="Small Logo"
            name="small_logo"
            fileList={this.props.logos.small_logo ? [{
              uid: '-1',
              name: this.props.logos.small_logo,
              status: 'done',
              url: `${CDN_URL}/global/images/${this.props.logos.small_logo}`,
            }] : []} />
          <Uploader
            label="Full Logo"
            name="full_logo"
            fileList={this.props.logos.full_logo ? [{
              uid: '-1',
              name: this.props.logos.full_logo,
              status: 'done',
              url: `${CDN_URL}/global/images/${this.props.logos.full_logo}`,
            }] : []} />
          <Uploader
            label="Favicon"
            name="favicon"
            fileList={this.props.logos.favicon ? [{
              uid: '-1',
              name: this.props.logos.favicon,
              status: 'done',
              url: `${CDN_URL}/global/images/${this.props.logos.favicon}`,
            }] : []} />
        </Row>


        <Form.Item label={<span>Privacy Policy URL <Tooltip title="This will be added to the dashboard and will be visible to your end users"><Icon type="info-circle" theme="filled" style={{ cursor: "pointer" }} /></Tooltip></span>}>
          {getFieldDecorator('privacypolicy_url', {
            rules: [{ required: true, message: 'This is a required field' }, { type: 'url', message: 'Please enter a valid URL' }],
          })(
            <Input />
          )}
        </Form.Item>
        <Form.Item label={<span>Terms URL <Tooltip title="This will be added to the dashboard and will be visible to your end users"><Icon type="info-circle" theme="filled" style={{ cursor: "pointer" }} /></Tooltip></span>}>
          {getFieldDecorator('tnc_url', {
            rules: [{ required: true, message: 'This is a required field' }, { type: 'url', message: 'Please enter a valid URL' }],
          })(
            <Input />
          )}
        </Form.Item>
        <Form.Item label={<span>GDPR DPA URL <Tooltip title="This will be added to the dashboard and will be visible to your end users"><Icon type="info-circle" theme="filled" style={{ cursor: "pointer" }} /></Tooltip></span>}>
          {getFieldDecorator('gdprdpa_url', {
            rules: [{ required: true, message: 'This is a required field' }, { type: 'url', message: 'Please enter a valid URL' }],
          })(
            <Input />
          )}
        </Form.Item>

        <Form.Item label="Helpdesk/Knowledgebase URL">
          {getFieldDecorator('kb_url', {
            rules: [{ required: true, message: 'This is a required field' }, { type: 'url', message: 'Please enter a valid URL' }],
          })(
            <Input />
          )}
        </Form.Item>
        <Form.Item label="Support URL">
          {getFieldDecorator('support_url', {
            rules: [{ required: true, message: 'This is a required field' }, { type: 'url', message: 'Please enter a valid URL' }],
          })(
            <Input />
          )}
        </Form.Item>
        <Form.Item label="Support Email">
          {getFieldDecorator('support_email', {
            rules: [{ required: true, message: 'This is a required field' }, { type: 'email', message: 'Please enter a valid email' }],
          })(
            <Input />
          )}
        </Form.Item>

        <Form.Item label="Submit Ticket Widget Code">
          {getFieldDecorator('submitticket_pixel', {
            rules: [{ required: false }],
          })(
            <Input.TextArea />
          )}
        </Form.Item>
        <Form.Item label="Live Chat Widget Code">
          {getFieldDecorator('livechat_pixel', {
            rules: [{ required: false }],
          })(
            <Input.TextArea />
          )}
        </Form.Item>

        <Form.Item>
          {getFieldDecorator('hide_product_links')(
            <Checkbox checked={getFieldValue('hide_product_links')} onChange={checked => setFieldsValue({ 'hide_product_links': checked })}>Hide Product Links </Checkbox>
          )}
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button" loading={this.state.saving}>Save</Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create()(GeneralSettings);